<template>
<!--  <div class="container-fluid no-scroll">-->
<!--    <div class="row bg-white" style="height: 4rem;">-->
<!--      <div class="col-12 fs-2 d-flex align-items-center justify-content-center">-->
<!--        Orders-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="container-fluid no-scroll mt-1">
    <div class="container">
      <div class="row bg-white px-0 small">
      <div class="col-3 text-center py-3" :class="{'active': status === 'All'}" @click="status='All'; loadOrders()">
          All
      </div>
      <div class="col-3 text-center py-3" :class="{'active': status === 'Processing'}" @click="status='Processing'; loadOrders()">
        Processing
      </div>
      <div class="col-3 text-center py-3" :class="{'active': status === 'Finished'}" @click="status='Finished'; loadOrders()">
        Finished
      </div>
      <div class="col-3 text-center py-3" :class="{'active': status === 'Cancel'}" @click="status='Cancel'; loadOrders()">
        Cancel
      </div>
    </div>
    </div>
  </div>

  <div class="container-fluid no-scroll mt-1">
    <div class="container">
    <div class="row px-0 small">
      <div v-for="order in orders" v-bind:key="order.id" class="col-12 bg-white mb-2 py-2">
        <div class="order">
          <div class="order-header">
            <span class="order-no">No.{{ order.order_id }}</span> <span class="order-time">{{ order.create_at }}</span>
          </div>

          <div class="order-body" v-for="(stock,index) in order.stocks" v-bind:key="index">
            <div class="info d-flex flex-column justify-content-around ml-1 flex-grow-1">
              <div class="model fw-bold">
                <span class="badge" style="background:#0B1423">{{ stock.grade }}</span> {{ stock.name }}
              </div>
              <div class="spec">
                <span class="attribute">{{ getSimpleSpecString(stock.specs) }} </span>
              </div>

              <div class="row mt-2 d-flex align-items-center justify-content-between">
                <div class="col-4 d-flex justify-content-between align-items-center">
                  <span class="price">{{ toThousands(stock.price) }}</span>
                  <span style="margin-left: 0.5rem;font-size:0.5rem; color: #777;">x {{ stock.quantity }}</span>
                </div>
                <div class="col-8 text-end">
                  {{ toThousands(stock.price * stock.quantity) }}
                </div>
              </div>
            </div>
          </div>

          <div class="order-footer">
            <div class="order-sum">
              <div class="row">
                <div class="col-2">
                  <a :href="getWhatsappLink(order)" target="_blank">
                    <fa class="service-icon" icon="whatsapp" type="fab"/>
                  </a>
                </div>
                <div class="col-10 d-flex justify-content-end">
                  <div class="d-flex justify-content-end align-items-baseline">
                    <span>Total: </span>
                    <span class="text-danger fw-bold mx-2 fs-5">
                      {{ toThousands(order.amount) }}
                  </span>
                    <span>{{ order.stocks[0]?.currency }}</span>
                  </div>

                  <span class="mx-3" style="color: #ccc">/</span>

                  <span class="text-danger fw-bold mx-2 fs-5">
                    {{ order.quantity}}
                  </span>
                  <span>
                    pcs
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasMoreItems" class="col-12 text-center my-3 small hover">
        <div class="text-dark" @click="loadMore()">
          <fa icon="angle-double-down"></fa>
          <br>Load More
        </div>
      </div>
      <div v-else class="col-12 text-center my-3 small hover">
        No more orders
      </div>
    </div>
    </div>
  </div>

</template>

<script>
import { getOrderList } from '@/api/order'
import { OrderStatusCode } from '@/api/codes'

import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import getSimpleSpecString from '../../api/specs'

export default {
  name: 'SimpleOrderList',

  setup () {
    const store = useStore()
    const user = computed(() => store.state.auth.user)
    const route = useRoute()
    const status = ref('')
    status.value = route.query.status || 'All'
    return {
      user,
      status
    }
  },

  watch: {
    selectedTab (newTab) {
      // 重置分页后加载订单
      this.resetPager()
      this.loadOrders()
    }
  },

  mounted () {
    this.loadOrders()
  },

  data () {
    return {
      statusDict: OrderStatusCode,

      orders: [],

      totalOrders: 0,
      itemsPerPage: 10,
      pager: {
        limit: 10,
        offset: 0
      },
      currentPage: 1,
      hasMoreItems: true
    }
  },

  methods: {
    loadMore () {
      this.$loading.show()
      this.pager.offset += this.itemsPerPage
      const params = {
        buyer_id: this.user.id,
        pager: this.pager
      }

      if (this.status !== 'All') {
        params.status = this.statusDict[this.status]
      }

      getOrderList(params).then(res => {
        if (res.code === 0) {
          if (res.data.orders.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
          this.orders.concat(res.data.orders)
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    getTotalQty (items) {
      let sum = 0
      // eslint-disable-next-line no-return-assign
      items.forEach(item => sum += item.quantity)
      return sum
    },

    loadOrders () {
      this.$loading.show('Loading order list...')

      const params = {
        buyer_id: this.user.id,
        pager: this.pager
      }

      if (this.status !== 'All') {
        params.status = this.statusDict[this.status]
      }

      getOrderList(params).then(res => {
        if (res.code === 0) {
          this.totalOrders = res.data.total
          this.orders = res.data.orders
          if (res.data.orders.length < this.itemsPerPage) {
            this.hasMoreItems = false
          }
        }
        this.$loading.hide()
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    resetPager () {
      this.currentPage = 1
      this.itemsPerPage = 10
      this.pager.limit = 10
      this.pager.offset = 0
      this.hasMoreItems = true
    },

    getWhatsappLink (order) {
      const orderInfo = encodeURI(`Order: ${order.order_id} \nDate: ${order.create_at} \nAmount:  ${order.amount} \nQuantity: ${order.quantity} \nStatus: ${this.status}`)
      const sellerPhone = '+9710525438790'
      return `//wa.me/${sellerPhone}?text=${orderInfo}`
    },

    getSimpleSpecString
  }
}
</script>

<style scoped lang="scss">

.active {
  font-weight: bold;
  border-bottom: 2px solid red;
}

.order {
  .order-header {
    padding: 0.8rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6D737C;

    .order-no {
      font-size: 1rem;
    }

    .order-time {
    }
  }

  .order-body {
    padding: 0.5rem 0;
    border-top: 1px solid #f5f5f5;
    display: flex;
    align-items: center;

    .info {
      padding-left: 1rem;

      .model {
        //font-size: 0.8rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .spec {
        margin: 0.3rem 0;
        font-size: 0.8rem;
        color: #6D737C;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .price {
        font-weight: bold;
      }
    }
  }

  .order-sum {
    font-size: 1.2rem;
  }

  .order-footer {
    border-top: 1px solid #f5f5f5;
    padding: 0.8rem 0 0.3rem 0;
  }

  .service-icon {
    width: 2rem;
    height: 2rem;
    color: #de350b;

    :hover {
      opacity: .6;
    }
  }
}

</style>
