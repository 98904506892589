<template>
  <HeaderV2></HeaderV2>
  <router-view/>
  <FooterInfo></FooterInfo>
  <Footer></Footer>
</template>

<script>

import HeaderV2 from '../HeaderV2'
import FooterInfo from '../FooterInfo'
import Footer from '../Footer'

export default {
  name: 'UserLayout',
  components: { FooterInfo, HeaderV2, Footer }
}
</script>

<style scoped>

</style>
